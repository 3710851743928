import React from 'react';
import style from './App.module.scss';
import './assets/styles/reset.css'
function App() {
  return (
      <div className={style.Wrapper}>
        <h1 className={style.Title}>Lootster</h1>
        <h3 className={style.SubTitle}>Coming Soon...</h3>
      </div>
  );
}

export default App;
